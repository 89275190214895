import React from 'react';
const DataList = props => {
    console.log(props.data);
    const leng = props.data.length;
    const data = props.data.map((singledata) => (
      <div
        className="d-flex flex-column flex-md-row pt-2 pb-2 mt-1 mb-4 text-center"
        key={singledata.id}
      >
        <div className="col-12 col-lg-7 w-100 h-100">
          <img className="img-fluid" src={singledata.img_src} alt="foto" />
        </div>
        <div className="col-12 col-lg-5 ml-2">
          <div className="results p-2">
            <h4 className="h6 text-white">Date the photo was taken</h4>
            <p className="text-white p1 mt-3">Mars sol: {singledata.sol}</p>
            <p className="text-white p1">Earth : {singledata.earth_date}</p>
            <p className="text-white p1">Camera :</p>
            <p className="text-white p1">{singledata.camera.full_name}</p>
          </div>
        </div>
      </div>
    ));
    if(leng > 0){
                  return (
                    <>
                      <h6 className="text-white ml-2 mt-4 mb-5 text-center">
                        Number of photos found:{" "}
                        <span className="ml-2">{leng}</span>
                      </h6>
                      {data}
                    </>
                  );
                } else { return (
                  <>
                    <div className="text-center text-white h3">
                      Change the search data
                    </div>
                    <p className="text-center text-white">Available dates:</p>
                    <p className="text-center text-white">
                      Curiosity sol 222-2804
                    </p>
                    <p className="text-center text-white">
                      Opportunity sol 1-222
                    </p>
                    <p className="text-center text-white">Spirit sol 1-218</p>
                    <p className="text-center text-white">
                      You can also try to change the camera
                    </p>
                    
                  </>
                );}
}
 
export default DataList;