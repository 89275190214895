import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import './App.css';
import marsRover from './img/mars-rover-1241266_1920small.jpg';
import DataList from './DataList';
//import ButtonFetchData from './ButtonFetchData'




class App extends Component {
  state = {
    data: null,
    sol: 1000,
    rover: "curiosity",
    camera: "FHAZ",
  };

  handleSolChange = (event) => {
    this.setState({
      sol: event.target.value,
    });
  };

  handleRoverChange = (event) => {
    this.setState({
      rover: event.target.value,
    });
  };

  handleCameraChange = (event) => {
    this.setState({
      camera: event.target.value,
    });
  };

  handleDataFetch = () => {
    const key = "ayZjEG6bgUPCFyztesWQfrR3PrW1pbn7L6fMXTL3";
    const API = `https://api.nasa.gov/mars-photos/api/v1/rovers/${this.state.rover}/photos?sol=${this.state.sol}&camera=${this.state.camera}&api_key=${key}`;

    fetch(API)
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw Error(response.status);
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          data: data.photos,
        });
      })
      .catch((error) => console.log(error));
  };

  render() {
    const data = this.state.data;
    
    
    return (
      <div className="container-fluid p-0 m-0">
        <div className="container pt-2">
          <nav class="navbar navbar-transparent mb-4 border-bottom">
            <a class="navbar-brand text-white" href="index.html">
              Mars<small className="h6"> project</small>
            </a>
          </nav>
          <div className="col d-flex flex-column flex-md-row justify-content-around form-box">
            <div className="col col-md-2">
              <form className="form">
                <div className="form-group mt-3 mt-md-0">
                  <label
                    className="d-none d-md-block col-form-label col-form-label-sm text-white"
                    htmlFor="sol_number"
                    value={this.state.sol}
                  >
                    {"Set sol:  "}
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="sol"
                    placeholder={this.state.sol}
                    value={this.state.sol}
                    onChange={this.handleSolChange}
                  ></input>
                </div>
              </form>
            </div>
            <div className="col col-md-4">
              <form className="form">
                <div className="form-group mt-3 mt-md-0">
                  <label
                    className="d-none d-md-block col-form-label col-form-label-sm text-white"
                    htmlFor="rover_choice"
                  >
                    Choose a rover
                  </label>
                  <select
                    className="form-control"
                    id="rover"
                    name="rover"
                    onChange={this.handleRoverChange}
                  >
                    <option value="curiosity" name="curiosity">
                      Curiosity
                    </option>
                    <option value="opportunity" name="opportunity">
                      Opportunity
                    </option>
                    <option value="spirit" name="spirit">
                      Spirit
                    </option>
                  </select>
                </div>
              </form>
            </div>
            <div className="col col-md-6">
              <form className="form">
                <div className="form-group mt-3 mt-md-0">
                  <label
                    className="d-none d-md-block col-form-label col-form-label-sm text-white"
                    htmlFor="camera_choice"
                  >
                    Choose camera :
                  </label>
                  <select
                    className="form-control form-contol-sm"
                    id="camera"
                    value={this.state.camera}
                    name="camera"
                    onChange={this.handleCameraChange}
                  >
                    <option value="FHAZ">Front Hazard Avoidance Camera</option>
                    <option value="RHAZ">Rear Hazard Avoidance Camera</option>
                    <option value="MAST">Mast Camera</option>
                    <option value="CHEMCAM">
                      Chemistry and Camera Complex
                    </option>
                    <option value="MAHLI">Mars Hand Lens Imager</option>
                    <option value="MARDI">Mars Descent Imager</option>
                    <option value="NAVCAM">Navigation Camera</option>
                    <option value="PANCAM">Panoramic Camera</option>
                    <option value="MINITES">
                      Miniature Thermal Emission Spectrometer - Mini-TES
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm-12 col-md-12 d-flex justify-content-center my-3">
              <button
                className="btn btn-primary btn-block col-sm-4"
                onClick={this.handleDataFetch}
              >
                Get the data
              </button>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row result-box">
            <div className="col-d-none col-md-6 data-box">
              {data ? <DataList data={data} /> : data}
            </div>
            <div className="col col-md-6 m-0 p-0 img-box">
              <img
                className="img-fluid"
                style={{ width: "100%", height: "100%" }}
                src={marsRover}
                alt="mars_rover"
              ></img>
            </div>
          </div>
          <footer class="page-footer font-small fixed-bottom">
            <div class="footer-copyright text-center py-3 text-white">
              Mars project © 2020 Copyright:
              <a className="text-white" href="index.html">
                {" "}
                Zbigniew Wyzinski
              </a>
            </div>
            <div class="footer-copyright text-center py-3 text-white-50">
              Images and Data © 2020 Copyright:
              <a className="text-white-50" href="https://api.nasa.gov/">
                {" "}
                Nasa Open API's
              </a>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
 
export default App;
